import React, { useRef, useMemo, useState, useEffect } from "react";
import { Box, useMediaQuery } from "@mui/system";
import { Button, Grid, Typography } from "@mui/material";
import Carousel from "react-elastic-carousel";
import Cheers from "../../../assets/quiz/cheersthegoodtimes.svg";
import CheersES from "../../../assets/quiz/cheersthegoodtimes_ES.svg";
import assets from "../../../assets";

import { useStyles } from "../styles";
import { QuizStepProps } from "..";

const QuizStep1 = ({ setQuizStep, lang, iframe }: QuizStepProps) => {
  const classes = useStyles();
  const carouselRef = useRef<any>(null);
  const between768pxto1180px = useMediaQuery('(min-width:600px) and (max-width:1180px)');
  const verticalCarouselRef = useRef<any>(null);
  const [autoPlay, setAutoPlay] = React.useState(true);
  // const[itemsToShow, setItemsToShow] = useState(1.5);

  let resetTimeout: string | number | NodeJS.Timeout | undefined;

  useEffect(() => {
    const handleResize = () => {
      setItemsToShow(getItemsToShow());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function getItemsToShow() {
    const width = window.innerWidth;
    if (width > 1200) return 3.5;
    if (width > 800) return 3.5;
    if (width < 900 && width > 500) return 2;
    return 1.5;
  }

  const [itemsToShow, setItemsToShow] = useState(getItemsToShow());

  useEffect(() => {
    const handleResize = () => {
      setItemsToShow(getItemsToShow());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function getItemsToShow() {
    const width = window.innerWidth;
    if (width > 1200) return 3.5;
    if (width > 800) return 3.5;
    if (width < 900 && width > 500) return 2;
    return 1.5;
  }

  const whatsyourcocktailtitle = useMemo(
    () => ({
      EN_UK: "What's your cocktail?",
      EN_US: "What's your cocktail?",
      EN_ROW: "What's your cocktail?",
      ES: "¿CUÁL ES TU CÓCTEL?",
      ES_ES: "¿CUÁL ES TU CÓCTEL?",
      ES_ROW: "¿CUÁL ES TU CÓCTEL?",
      ES_US: "¿CUÁL ES TU CÓCTEL?",
      ES_MX: "¿CUÁL ES TU CÓCTEL?",
    }),
    []
  );

  const selectiontitle = useMemo(
    () => ({
      EN_UK: "A selection of cocktails, made just for you",
      EN_US: "A selection of cocktails, made just for you",
      EN_ROW: "A selection of cocktails, made just for you",
      ES: "Una selección de cócteles hecha solo para ti",
      ES_ES: "Una selección de cócteles hecha solo para ti",
      ES_MX: "Una selección de cócteles hecha solo para ti",
      ES_ROW: "Una selección de cócteles hecha solo para ti",
      ES_US: "Una selección de cócteles hecha solo para ti",
    }),
    []
  );

  const tellustitle = useMemo(
    () => ({
      EN_UK: "Tell us which moment you want to enjoy your cocktails in and the foods that match your favourite flavours, and we'll recommend cocktails personalised to you.",
      EN_US: "Tell us which moment you want to enjoy your cocktails in and the foods that match your favourite flavours, and our FlavorPrint® AI  will recommend cocktails personalised to you. Whether you're cheering on a personal win or sharing a moment with friends, we will help you find that ideal cocktail.",
      ES: "Cuéntanos en qué ocasión quieres disfrutar de tus cócteles y selecciona los platos que combinen tus sabores favoritos, y te recomendaremos cócteles personalizados a tu gusto.",
      ES_US: "Cuéntanos en qué ocasión quieres disfrutar de tus cócteles y selecciona los platos que combinen tus sabores favoritos, y te recomendaremos cócteles personalizados a tu gusto.",
      ES_MX: "Cuéntanos en qué ocasión quieres disfrutar de tus cócteles y selecciona los platos que combinen tus sabores favoritos, y te recomendaremos cócteles personalizados a tu gusto.",
      ES_ROW: "Cuéntanos en qué ocasión quieres disfrutar de tus cócteles y selecciona los platos que combinen tus sabores favoritos, y te recomendaremos cócteles personalizados a tu gusto.",
      ES_ES: "Cuéntanos en qué ocasión quieres disfrutar de tus cócteles y selecciona los platos que combinen tus sabores favoritos, y te recomendaremos cócteles personalizados a tu gusto.",
    }),
    []
  );

  const wethertitle = useMemo(
    () => ({
      EN_UK: "Whether you're cheering on a personal win or sharing a moment with friends, we will help you find that ideal cocktail.",
      EN_US: "Whether you're cheering on a personal win or sharing a moment with friends, we will help you find that ideal cocktail.",
      EN_ROW: "Whether you're cheering on a personal win or sharing a moment with friends, we will help you find that ideal cocktail.",
      ES: "Sea para celebrar un triunfo personal o compartir un momento con amigos, te ayudaremos a encontrar el cóctel perfecto.",
      ES_ES: "Sea para celebrar un triunfo personal o compartir un momento con amigos, te ayudaremos a encontrar el cóctel perfecto.",
      ES_MX: "Sea para celebrar un triunfo personal o compartir un momento con amigos, te ayudaremos a encontrar el cóctel perfecto.",
      ES_ROW: "Sea para celebrar un triunfo personal o compartir un momento con amigos, te ayudaremos a encontrar el cóctel perfecto.",
      ES_US: "Sea para celebrar un triunfo personal o compartir un momento con amigos, te ayudaremos a encontrar el cóctel perfecto.",
    }),
    []
  );

  const letsgotitle = useMemo(
    () => ({
      EN_UK: "LET'S GO",
      EN_US: "LET'S GO",
      EN_ROW: "LET'S GO",
      ES: "VAMOS ALLÁ",
      ES_ES: "VAMOS ALLÁ",
      ES_US: "VAMOS ALLÁ",
      ES_MX: "VAMOS ALLÁ",
      ES_ROW: "VAMOS ALLÁ",
    }),
    []
  );

  const cheerstitle = useMemo(
    () => ({
      EN_UK: Cheers,
      EN_US: Cheers,
      EN_ROW: Cheers,
      ES: CheersES,
      ES_ES: CheersES,
      ES_US: CheersES,
      ES_MX: CheersES,
      ES_ROW: CheersES,
    }),
    []
  );



  const title = whatsyourcocktailtitle[lang] || "What's your cocktail?";
  const title_selection = selectiontitle[lang] || "A selection of cocktails, made just for you";
  const title_tellus = tellustitle[lang] || tellustitle["EN_UK"];
  const title_whether = wethertitle[lang] || wethertitle["EN_UK"];
  const title_letsgo = letsgotitle[lang] || letsgotitle["EN_UK"];
  const title_cheers = cheerstitle[lang] || cheerstitle["EN_UK"];

  const CLOUDFRONT_URL = process.env.REACT_APP_CDN;

  /*
  const Orange = `${CLOUDFRONT_URL}/public/FE/quiz1/Margarita Hero.jpg`
  const Blue = `${CLOUDFRONT_URL}/public/FE/quiz1/Manhattan Hero.jpg`
  const Yellow = `${CLOUDFRONT_URL}/public/FE/quiz1/Paloma Additional 2.jpg`
  const Green = `${CLOUDFRONT_URL}/public/FE/quiz1/Tequila Sunrise Hero.jpg`
  */


  const handleCarouselMouseEvent = () => {
    setAutoPlay(!autoPlay);
  };
  useEffect(() => {
    if (between768pxto1180px) {
      setItemsToShow(2.5)
    } else {
      setItemsToShow(1.5)
    }
  }, [between768pxto1180px])

  console.log("Assets location", assets.quiz1.jpg.orange)

  return (
    <Box
      className={classes.firstStepRoot}
      sx={{
        flexDirection: {
          xs: "column !important",
          sm: "column !important",
          md: "row !important",
          lg: "row !important",
          xl: "row !important",
        },
        justifyContent: {
          xs: "unset !important",
          sm: "unset !important",
          md: "center !important",
          lg: "center !important",
          xl: "center !important",
        },
        paddingTop: {
          xs: "2rem !important",
          sm: "2rem !important",
          md: "0rem !important",
          lg: "0rem !important",
          xl: "0rem !important",
        },
      }}
    >
      {/* Desktop */}
      <Grid container component={Box} display={{ xs: "none", md: "flex" }}>
        <Grid
          item
          md={6}
          className={iframe === 'worldclass' || iframe === 'stand' ? 'world_class_step1' : ''}
          sx={{
            height: "100vh",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            paddingTop: iframe === 'stand' || iframe === 'worldclass' ? undefined : undefined,
          }}
        >
          <Box className={classes.firstStepDescriptionContainer}>
            <Box>
              <Typography gutterBottom className={classes.WYCTitle}>
                {title}
              </Typography>
            </Box>

            <Box>
              <Typography gutterBottom className={classes.firstStepTitle}>
                {lang === 'ES' || lang === 'ES_ES' || lang === 'ES_MX' || lang === 'ES_US'
                  || lang === 'ES_ROW' ? (
                  <>
                    Una selección de cócteles hecha solo
                    <br />
                    para ti
                  </>
                ) : (
                  title_selection
                )}
              </Typography>
            </Box>
            <Box sx={{ width: "415px", marginBottom: "2rem" }}>
              <Typography
                className={classes.firstStepNormalText}
                sx={{ marginBottom: "2rem" }}
              >
                {title_tellus}
              </Typography>
              <Typography className={classes.firstStepNormalText}>
                {title_whether}
              </Typography>
            </Box>
            <Box width={"343px"} textAlign={"center"} display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}>
              <Button
                fullWidth
                className={classes.firstStepButton}
                onClick={() => {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: "custom_event",
                    event_name: "quiz_start",
                  });
                  setQuizStep(2);
                }}

                sx={{ marginBottom: "1rem" }}
              >
                {title_letsgo}
              </Button>
              {lang === 'ES' || lang === 'ES_ES' || lang === 'ES_MX' || lang === 'ES_US'
                || lang === 'ES_ROW' ? (
                <img
                  src={title_cheers}
                  alt="cheers the good times!"
                  style={{ width: "110%" }}
                />
              ) : (
                <img
                  src={title_cheers}
                  alt="cheers the good times!"
                  style={{ width: "85%", marginLeft: "-5%" }}
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            height: "100vh",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onMouseEnter={handleCarouselMouseEvent}
          onMouseLeave={handleCarouselMouseEvent}
        >
          <Carousel
            ref={verticalCarouselRef}
            verticalMode
            itemsToShow={getItemsToShow()}
            enableAutoPlay={autoPlay}
            autoPlaySpeed={300}
            renderArrow={() => <></>}
            renderPagination={() => <></>}
            itemPadding={[10, 0]}
            onNextEnd={({ index }) => {
              if (index + 1 === 4) {
                clearTimeout(resetTimeout);
                if (verticalCarouselRef && verticalCarouselRef.current) {
                  resetTimeout = setTimeout(() => {
                    if (verticalCarouselRef && verticalCarouselRef.current)
                      verticalCarouselRef.current.goTo(0);
                  }, 300);
                }
              }
            }}
          >
            <img
              src={assets.quiz1.jpg.yellow}
              style={{
                width: "637px",
                height: "477.75px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Yellow Cocktail"
              onError={(e) => {
                e.target.src = assets.quiz1.jpg.yellow; // Replace with your fallback image URL
                e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
              }}
            />
            <img
              src={assets.quiz1.jpg.orange}
              style={{
                width: "637px",
                height: "477.75px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Orange Cocktail"
              onError={(e) => {
                e.target.src = assets.quiz1.jpg.orange; // Replace with your fallback image URL
                e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
              }}
            />
            <img
              src={assets.quiz1.jpg.blue}
              style={{
                width: "637px",
                height: "477.75px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Blue Cocktail"
              onError={(e) => {
                e.target.src = assets.quiz1.jpg.blue; // Replace with your fallback image URL
                e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
              }}
            />
            <img
              src={assets.quiz1.jpg.green}
              style={{
                width: "637px",
                height: "477.75px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Green Cocktail"
              onError={(e) => {
                e.target.src = assets.quiz1.jpg.green; // Replace with your fallback image URL
                e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
              }}
            />

          </Carousel>
        </Grid>
      </Grid>
      {/* Mobile */}
      <Grid container component={Box} display={{ xs: "block", md: "none" }}>
        <Grid
          item
          xs={12}
          sx={{
            height: "530px",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            className={classes.firstStepDescriptionContainer}
            sx={{ paddingLeft: "3rem !important", paddingRight: "3rem !important", paddingTop: "0 !important", margin: "0!important" }}
          >
            <Box>
              <Typography className={classes.WYCTitleMobile}>
                {title}
              </Typography>
            </Box>

            <Box>
              <Typography gutterBottom className={classes.firstStepTitleMobile}>
                {title_selection}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", marginBottom: "2rem" }}>
              <Typography
                className={classes.firstStepNormalTextMobile}
                sx={{ marginBottom: "1rem" }}
              >
                {title_tellus}
              </Typography>
              <Typography className={classes.firstStepNormalTextMobile}>
                {title_whether}
              </Typography>
            </Box>
            <Box width={"100%"} textAlign={"center"} display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}>
              <Button
                fullWidth
                className={classes.firstStepButton}
                onClick={() => {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: "custom_event",
                    event_name: "quiz_start",
                  });
                  setQuizStep(2);
                }}
                sx={{ marginBottom: "1rem" }}
              >
                {title_letsgo}
              </Button>

              <img src={title_cheers} alt="cheers the good times!" width={"90%"} marginLeft={"0"} />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container component={Box} display={{ xs: "block", md: "none" }}>
        <Grid
          item
          xs={12}
          sx={{
            height: "231px",
            overflow: "hidden",
            "& .rec-slider-container": {
              marginRight: "0 !important",
            },
          }}
          onMouseEnter={handleCarouselMouseEvent}
          onMouseLeave={handleCarouselMouseEvent}
        >
          <Carousel
            ref={carouselRef}
            itemsToShow={itemsToShow}
            enableAutoPlay={autoPlay}
            autoPlaySpeed={300}
            renderArrow={() => <></>}
            renderPagination={() => <></>}
            itemPadding={[0, 0, 0, 10]}
            onNextEnd={({ index }) => {
              if (index + 1 === 3) {
                clearTimeout(resetTimeout);
                if (carouselRef && carouselRef.current) {
                  resetTimeout = setTimeout(() => {
                    if (carouselRef && carouselRef.current)
                      carouselRef.current.goTo(0);
                  }, 1500);
                }
              }
            }}
          >
            <img
              src={assets.quiz1.jpg.orange}
              style={{
                width: "276px",
                height: "207px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Orange Cocktail"
              onError={(e) => {
                e.target.src = assets.quiz1.jpg.orage; // Replace with your fallback image URL
                e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
              }}
            />
            <img
              src={assets.quiz1.jpg.blue}
              style={{
                width: "276px",
                height: "207px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Blue Cocktail"
              onError={(e) => {
                e.target.src = assets.quiz1.jpg.blue; // Replace with your fallback image URL
                e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
              }}
            />
            <img
              src={assets.quiz1.jpg.green}
              style={{
                width: "276px",
                height: "207px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Green Cocktail"
              onError={(e) => {
                e.target.src = assets.quiz1.jpg.green; // Replace with your fallback image URL
                e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
              }}
            />
            <img
              src={assets.quiz1.jpg.yellow}
              style={{
                width: "276px",
                height: "207px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Yellow Cocktail"
              onError={(e) => {
                e.target.src = assets.quiz1.jpg.yellow; // Replace with your fallback image URL
                e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
              }}
            />
            <img
              src={assets.quiz1.jpg.green}
              style={{
                width: "276px",
                height: "207px",
                borderRadius: "46.16px",
                display: "block",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                objectFit: "cover",
              }}
              alt="Green Cocktail"
              onError={(e) => {
                e.target.src = assets.quiz1.jpg.green; // Replace with your fallback image URL
                e.target.onerror = null; // Prevents an infinite loop in case the fallback image also fails to load
              }}
            />
          </Carousel>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuizStep1;
