import React from "react";
import { useLocation, Navigate, Outlet, useRoutes } from "react-router-dom";
import { Box } from "@mui/material";
import { AuthGuard, GuestGuard } from "../route-guards";
// Layouts
import MainLayout from "../layouts/MainLayout";
import ErrorPage from "../layouts/ErrorPage";
import NotFoundPage from "../layouts/NotFound";
// Widget
import Widget from "../views/widget";
// Recipe Viewers
import RecipeViewer from "../views/recipes";
// Quiz
import QuizPage from "../views/quiz";

export default function AppRoutes() {

  const location = useLocation();
  const hasQueryString = location.search;

  const routes = useRoutes([
    // Widget
    {
      path: "/",
      element: hasQueryString ? (
        <Widget />
      ) : (
        <Navigate replace to={"/quiz/test_site/EN_UK/thebar"} />
      ),
    },
    // Recipe Viewers
    {
      path: "/recipe/:brand/:cocktailName/:language/:iframe",
      element: <RecipeViewer />,
      errorElement: <ErrorPage />,
    },
    // Quiz
    {
      path: "/quiz/:site/:lang/:brand/:iframe?",
      element: <QuizPage />,
      errorElement: <ErrorPage />,
    }
    /*
    {
      path: "/implementation-quiz",
      element: <TestQuizPage />,
      errorElement: <ErrorPage />,
    },
    // Management
    {
      path: "/upload_cocktail",
      element: <Navigate replace to={"/upload_cocktail/cocktails"} />,
      errorElement: <NotFoundPage />,
    },
    
    {
      path: "/upload_cocktail/auth",
      element: (
        <Box>
          <Outlet />
        </Box>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          path: "",
          element: <Navigate replace to={"/upload_cocktail/auth/login"} />,
        },
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: "/upload_cocktail",
      element: <MainLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "",
          element: <Navigate replace to={"/upload_cocktail/cocktails"} />,
        },
        {
          path: "cocktails",
          element: (
            <AuthGuard>
              <CocktailsPage />
            </AuthGuard>
          ),
        },
        {
          path: "cocktails/create",
          element: (
            <AuthGuard>
              <CreateCocktailsPage />
            </AuthGuard>
          ),
        },
        {
          path: "cocktails/edit/:cocktailName/:lang",
          element: (
            <AuthGuard>
              <EditCocktailsPage />
            </AuthGuard>
          ),
        },
        {
          path: "cocktails/view/:cocktailName/:lang",
          element: (
            <AuthGuard>
              <ViewCocktailsPage />
            </AuthGuard>
          ),
        },
      ],
    },
    */,
    // Default redirection
    {
      path: "/404",
      element: <NotFoundPage />,
      errorElement: <NotFoundPage />,
    },
    {
      path: "*",
      element: <Navigate replace to={"/404"} />,
      errorElement: <NotFoundPage />,
    },
  ]) as React.ReactElement;

  return routes
}
